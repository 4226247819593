import { createTheme } from '@mui/material/styles';

const RiTheme = createTheme({
    // Colors
    palette: {
        mode: 'dark',
        primary: {
            main: '#007AC2'
        },
        secondary: {
            main: '#ffffff'
        },
        riBlue: {
            main: '#007AC2'
        },
        riRed: {
            main: '#E4022B'
        },
        riYellow: {
            main: '#F3D03E'
        },
        background: {
            default: '#1a1c1e'
        }
    },

    // Typography
    typography: {
        fontFamily: 'Inter, sans-serif',
        h1: {
            lineHeight: 1.16,
            fontFamily: 'Inter var, sans-serif',
            fontSize: '56px',
            letterSpacing: '-0.05em',
            fontWeight: 800,
            fontVariationSettings: '"wght" 800, "slnt" 0, "opsz" 14',
            fontFeatureSettings:
                '"case" 0, "cpsp" 0, "dlig" 0, "frac" 0, "dnom" 0, "numr" 0, "salt" 0, "subs" 0, "sups" 0, "tnum" 0, "zero" 0, "ss01" 0, "ss02" 0, "ss03", "ss04" 0, "cv01" 0, "cv02" 0, "cv03" 0, "cv04" 0, "cv05" 0, "cv06" 0, "cv07" 0, "cv08 0", "cv09" 0, "cv10" 0, "cv11" 0, "calt", "ccmp", "kern"'
        },
        stainlessH1: {
            fontFamily: 'Stainless, sans-serif',
            fontSize: '64px'
        },
        h2: {
            // H2: 'Single' page title using bolded Inter font
            fontFamily: 'Inter var, sans-serif',
            fontSize: '48px',
            letterSpacing: '-0.028em',
            fontVariationSettings: '"wght" 800, "slnt" 0, "opsz" 14',
            fontWeight: 800,
            // WebkitFontSmoothing: 'initial',
            fontFeatureSettings:
                '"case" 0, "cpsp" 0, "dlig" 0, "frac" 0, "dnom" 0, "numr" 0, "salt" 0, "subs" 0, "sups" 0, "tnum" 0, "zero" 0, "ss01" 0, "ss02" 0, "ss03" 0, "ss04" 0, "cv01" 0, "cv02" 0, "cv03" 0, "cv04" 0, "cv05" 0, "cv06" 0, "cv07" 0, "cv08" 0, "cv09" 0, "cv10" 0, "cv11", "calt", "ccmp", "kern"'
        },
        stainlessH2: {
            fontFamily: 'Stainless, sans-serif',
            fontSize: '46px'
        },
        h3: {
            fontFamily: 'Inter var, sans-serif',
            fontStyle: 'normal',
            fontSize: '36px',
            fontFeatureSettings:
                '"case" 0, "cpsp" 0, "dlig" 0, "frac" 0, "dnom" 0, "numr" 0, "salt" 0, "subs" 0, "sups" 0, "tnum" 0, "zero" 0, "ss01" 0, "ss02" 0, "ss03" 0, "ss04" 0, "cv01" 0, "cv02" 0, "cv03", "cv04", "cv05" 0, "cv06" 0, "cv07" 0, "cv08" 0, "cv09" 0, "cv10" 0, "cv11" 0, "calt", "ccmp", "kern"',
            fontVariationSettings: '"wght" 700, "slnt" -10, "opsz" 14',
            fontWeight: 700,
            letterSpacing: '-0.028em'
            // WebkitFontSmoothing: 'auto'
        },
        stainlessH3: {
            fontFamily: 'Stainless, sans-serif',
            fontSize: '38px'
        },
        h4: {
            fontFamily: 'Inter var, sans-serif',
            fontSize: '30px',
            fontVariationSettings: '"wght" 700',
            fontWeight: 700,
            fontFeatureSettings:
                '"case" 0, "cpsp" 0, "dlig" 0, "frac" 0, "dnom" 0, "numr" 0, "salt" 0, "subs" 0, "sups" 0, "tnum" 0, "zero" 0, "ss01" 0, "ss02" 0, "ss03" 0, "ss04" 0, "cv01" 0, "cv02" 0, "cv03" 0, "cv04" 0, "cv05" 0, "cv06" 0, "cv07" 0, "cv08" 0, "cv09" 0, "cv10" 0, "cv11" 0, "calt", "ccmp", "kern"'
        },
        h5: {
            fontFamily: 'Inter var, sans-serif',
            fontWeight: 700,
            fontVariationSettings: '"wght" 700'
        },
        h6: {
            fontSize: '1.2rem',
            fontWeight: 700
        },
        subtitle1: {
            fontWeight: 546,
            fontSize: '16px',
            letterSpacing: '-0.027em',
            // WebkitFontSmoothing: 'initial',
            fontFeatureSettings:
                '"case" 0, "cpsp" 0, "dlig" 0, "frac" 0, "dnom" 0, "numr" 0, "salt" 0, "subs" 0, "sups" 0, "tnum" 0, "zero" 0, "ss01" 0, "ss02" 0, "ss03" 0, "ss04" 0, "cv01" 0, "cv02" 0, "cv03" 0, "cv04" 0, "cv05" 0, "cv06" 0, "cv07" 0, "cv08" 0, "cv09" 0, "cv10" 0, "cv11" 0, "calt", "ccmp", "kern"',
            lineHeight: '20px',
            fontStyle: 'oblique'
        },
        subtitle2: {
            fontFamily: 'Inter var, sans-serif',
            fontVariationSettings: '"wght" 600'
        },
        subtitle3: {
            fontFamily: 'Inter var, sans-serif',
            fontVariationSettings: '"wght" 500',
            fontSize: '14px'
        },
        chartHeader: {
            fontFamily: 'Stainless, sans-serif',
            fontWeight: 700,
            fontSize: '48px'
        },
        chartSubHeader: {
            fontFamily: 'Stainless, sans-serif',
            fontWeight: 500,
            fontSize: '24px'
        },
        body1: {
            lineHeight: 1.25
        },
        body2: {
            lineHeight: 1.25
        },
        body3: {
            fontSize: '12px'
        }
    },

    // Components
    components: {
        // MUI Menu
        MuiPaper: {
            styleOverrides: {
                root: {
                    // no dropshadow
                    boxShadow: 'none'
                    //   backgroundColor: '#202020',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    overflow: 'auto'
                }
            },
            defaultProps: {
                variant: 'scrollable',
                textColor: 'secondary'
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    colorScheme: 'dark'
                }
            }
        }
    }
});

export default RiTheme;
